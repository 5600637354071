import type { LocalePrefix } from "node_modules/next-intl/dist/types/src/shared/types";

const localePrefix: LocalePrefix = "never";

// FIXME: Update this configuration file based on your project information
export const AppConfig = {
  name: "TravelThru",
  // locales: ["en"],
  locales: ["en", "vi"],
  defaultLocale: "en",
  localePrefix,
  /**
   * The default redirect path after logging in
   * @type {string}
   */
  defaultLoginRedirect: "/dashboard",
  /**
   * An array of routes that are accessible to the public
   * These routes do not require authentication
   * @type {string[]}
   */
  publicRoutes: ["/"],
  /**
   * An array of routes that are used for authentication
   * These routes will redirect logged in users to (auth)
   * @type {string[]}
   */
  authRoutes: ["/dashboard", "/dashboard/content"],
  /**
   * The prefix for API authentication routes
   * Routes that start with this prefix are used for API authentication purposes
   * @type {string}
   */
  apiPrefix: "/api",
};

export const UnauthRoute = {
  home: "/",
  about: "/about",
  contact: "/contact",
  albaLoyalty: "/alba-loyalty",
  albaLoyaltyTermsAndConditions: "/alba-loyalty/terms-and-conditions",
  becomeOurPartner: "/become-our-partner",
  booking: "/booking",
  ourSolutions: "/our-solutions",
  airportTransfer: "/airport-transfer",
  esim: "/airport-transfer/e-sim",
  gohub: "/airport-transfer/e-sim/gohub",
  news: "/news",
  photoChallenge: "/photo-challenge",
  notFound: "/not-found",
};
