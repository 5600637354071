"use client";

import { useLocale } from "next-intl";
import { type ChangeEventHandler } from "react";

import { usePathname, useRouter } from "@/libs/i18nNavigation";
import { AppConfig } from "@/utils/AppConfig";
import { Select, SelectItem } from "@nextui-org/react";
import capitalize from "@/utils/capitalize";
import { defaultCountries, locales } from "@/utils/locales";
import Image from "next/image";
import { cn } from "@/libs/cn";

export default function LocaleSwitcher(props: { value?: string }) {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const newLocale = event.target.value;
    if (locale !== newLocale) {
      router.push(pathname, { locale: newLocale });
      if (pathname == "/" || pathname == "/vi" || pathname == "/vi/test") {
        window.location.reload();
      }
    }
  };

  return (
    <div className="flex flex-row  ">
      <Select
        defaultSelectedKeys={[locale]}
        size="sm"
        startContent={
          <Image
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${defaultCountries[locale as "vi" | "en"]}.svg`}
            width={20}
            height={20}
            alt={locale}
            className={cn(locale == "vi" && "mt-1")}
          />
        }
        endContent={
          <svg
            xmlns="http://www.w3.org/2000/svg" width="20" height="20"
            className={cn("  hidden md:flex", locale == "vi" && "!mt-1")}
            viewBox="0 0 512 512"
          >
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144"></path>
          </svg>
        }
        classNames={{
          label: "hidden",
          mainWrapper: "!bg-black flex gap-0 md:gap-3 shadow-none",
          trigger: "bg-black !text-white shadow-none px-0 xl:px-1 hover:!bg-transparent",
          value: `!text-white text-[16px] ${props?.value}`,
          innerWrapper: "w-fit",
          selectorIcon: "px-0 mx-0 hidden ",
          listboxWrapper: "w-fit",
          popoverContent: "w-[100px]",
        }}
        disallowEmptySelection={true}
        onChange={handleChange}
        listboxProps={{
          itemClasses: {
            base: ["px-4"],
          },
        }}
      >
        {AppConfig.locales.map((elt: keyof typeof locales) => (
          <SelectItem
            key={elt}
            value={elt}
            className=" font-degular-display-semibold"
          >
            {capitalize(locales[elt])}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
}
