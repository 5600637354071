"use client";

import Image from "next/image";
import {
  Navbar,
  NavbarContent,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Accordion,
  AccordionItem,
  type Selection,
} from "@nextui-org/react";
import { useReducer, useState } from "react";
import Link from "next/link";
import { useTranslations } from "next-intl";
import LocaleSwitcher from "@/components/LocaleSwitcher";

function MobileNav() {
  const [isMenuOpen, setIsMenuOpen] = useReducer((current) => !current, false);
  const t = useTranslations("RootLayout");
  const [selectedKeys, setSelectedKeys] = useState<Selection>();

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      isBlurred={false}
      classNames={{
        base: "bg-transparent ",
      }}
      className=" w-[20px] xl:hidden "
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className=""
          icon={
            isMenuOpen ? (
              <Image
                alt="close"
                src={"/assets/images/close.svg"}
                width={10}
                height={10}
              />
            ) : (
              <Image
                alt="open"
                src={"/assets/images/menu.svg"}
                width={12}
                height={8}
              />
            )
          }
        />
      </NavbarContent>
      <NavbarMenu className=" !px-0 space-y-[12px] pt-[40px]">
        <NavbarMenuItem className="px-[24px]">
          <Link
            href="/about"
            className="text-black !no-underline hover:text-black font-degular-display-semibold !text-[28px]"
            onClick={() => setIsMenuOpen()}
          >
            {t("about_link")}
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem className="px-[24px]">
          <Accordion
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
            className=" !px-0"
            showDivider={false}
            itemClasses={{
              trigger: "py-0 " 
            }}
          >
            <AccordionItem
              key="1"
              aria-label={t("services_link")}
              title={t("services_link")}
              className="mb-[22px]"
              classNames={{
                title: "font-degular-display-semibold !text-[28px]",
                base: " space-y-[0px] py-0",
              }}
              disableIndicatorAnimation={true}
              indicator={({ isOpen }) =>
                isOpen ? (
                  <Image
                    alt="up"
                    src={"/assets/images/icon-up.svg"}
                    width={17}
                    height={10}
                  />
                ) : (
                  <Image
                    alt="down"
                    src={"/assets/images/icon-down.svg"}
                    width={17}
                    height={10}
                  />
                )
              }
            >
              <ul className="">
                <li>
                  {/* <Link
                    href={"/"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("a_to_b_rides_link")}
                  </Link> */}
                </li>
                <li>
                  <a
                    href={"/a-to-b-rides"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("a_to_b_rides_link")}
                  </a>
                </li>
                <li>
                  <a
                    href={"/airport-transfer"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("airport_transfers_link")}
                  </a>
                </li>
                <li>
                  <a
                    href={"/chauffeur-service"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("chauffeur_service_link")}
                  </a>
                </li>
                <li>
                  <a
                    href={"/group-booking"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("group_booking_link")}
                  </a>
                </li>
              </ul>
            </AccordionItem>
            {/* <NavSubItem title={t("a_to_b_rides_link")} href="a-to-b-rides" />
            <NavSubItem
              title={t("airport_transfers_link")}
              href="/airport-transfer"
            />
            <NavSubItem
              title={t("chauffeur_service_link")}
              href="/chauffeur-service"
            />
            <NavSubItem title={t("group_booking_link")} href="/group-booking" /> */}
            <AccordionItem
              key="2"
              aria-label={t("programs_link")}
              title={t("programs_link")}
              className="mb-[22px]"
              classNames={{
                title: "font-degular-display-semibold !text-[28px]",
                base: " space-y-[0px] py-0",
              }}
              disableIndicatorAnimation={true}
              indicator={({ isOpen }) =>
                isOpen ? (
                  <Image
                    alt="up"
                    src={"/assets/images/icon-up.svg"}
                    width={17}
                    height={10}
                  />
                ) : (
                  <Image
                    alt="down"
                    src={"/assets/images/icon-down.svg"}
                    width={17}
                    height={10}
                  />
                )
              }
            >
              <ul>
                <li>
                  <Link
                    href={"/alba-loyalty"}
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("alba_loyalty_link")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/photo-challenge"
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("photo_challenge_link")}
                  </Link>
                </li>
              </ul>
            </AccordionItem>
            <AccordionItem
              key="3"
              aria-label={t("bussiness_link")}
              title={t("bussiness_link")}
              className=" "
              classNames={{
                title: "font-degular-display-semibold !text-[28px]",
                base: " space-y-[0px] py-0",
              }}
              disableIndicatorAnimation={true}
              indicator={({ isOpen }) =>
                isOpen ? (
                  <Image
                    alt="up"
                    src={"/assets/images/icon-up.svg"}
                    width={17}
                    height={10}
                  />
                ) : (
                  <Image
                    alt="down"
                    src={"/assets/images/icon-down.svg"}
                    width={17}
                    height={10}
                  />
                )
              }
            >
              <ul>
                <li>
                  <Link
                    href="/our-solutions"
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("our_solutions_link")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/become-our-client"
                    className="text-[18px] text-black !no-underline hover:text-black"
                    onClick={() => setIsMenuOpen()}
                  >
                    {t("become_our_client_link")}
                  </Link>
                </li>
              </ul>
            </AccordionItem>
          </Accordion>
        </NavbarMenuItem>
        <NavbarMenuItem className="px-[24px]">
          <Link
            href="https://extra.travelthru.com/"
            target="_blank"
            className="text-black !no-underline hover:text-black font-degular-display-semibold !text-[28px]"
            onClick={() => setIsMenuOpen()}
          >
            {"Extra"}
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem className="px-[24px]">
          <Link
            href="/news"
            className="text-black !no-underline hover:text-black font-degular-display-semibold !text-[28px]"
            onClick={() => setIsMenuOpen()}
          >
            {t("news_link")}
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem className="px-[24px]  ">
          <Link
            href="https://support.travelthru.com/"
            target="_blank"
            className="text-black !no-underline hover:text-black font-degular-display-semibold !text-[28px]"
            onClick={() => setIsMenuOpen()}
          >
            {t("help_link")}
          </Link>
        </NavbarMenuItem>
        {/* <NavbarMenuItem className="px-[24px] mt-[32px]">
          <div
            className="flex cursor-pointer flex-row items-center 
            font-degular-display-semibold space-x-[12px] rounded-full
            "
          >
            <div className="w-fit bg-gray-300 rounded">
              <LocaleSwitcher />
              <Image
                alt="_"
                src="/assets/images/down-arrow.png"
                width={9}
                height={5}
                className=" object-cover hidden md:flex"
              />
            </div>
          </div>
        </NavbarMenuItem> */}
      </NavbarMenu>
    </Navbar>
  );
}

export default MobileNav;
