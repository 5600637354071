"use client";

import { usePathname } from 'next/navigation'
import Link from "next/link";
import { useStore } from '@/stores';
import usePersistStore from '@/hooks/usePersistStore';
import { cn } from '@/libs/cn';
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure
} from '@nextui-org/react';
import { getCurrencySymbol } from '@/utils/formatCurrency';
import { useTranslations } from 'next-intl';

function RightNav() {
  const t = useTranslations("BaseTemplate");
  const suggestedCurrencyList = [
    { value: "EUR", title: t("currency_selector.currencies.EUR") },
    { value: "USD", title: t("currency_selector.currencies.USD") },
    { value: "AUD", title: t("currency_selector.currencies.AUD") },
    { value: "NZD", title: t("currency_selector.currencies.NZD") },
    { value: "THB", title: t("currency_selector.currencies.THB") },
    { value: "CAD", title: t("currency_selector.currencies.CAD") },
    { value: "SGD", title: t("currency_selector.currencies.SGD") },
    { value: "CNY", title: t("currency_selector.currencies.CNY") },
    { value: "HKD", title: t("currency_selector.currencies.HKD") },
    { value: "PHP", title: t("currency_selector.currencies.PHP") }
  ]

  const currencyList = [
    { value: "MYR", title: t("currency_selector.currencies.MYR") },
    { value: "TWD", title: t("currency_selector.currencies.TWD") },
    { value: "KRW", title: t("currency_selector.currencies.KRW") },
    { value: "ILS", title: t("currency_selector.currencies.ILS") },
    { value: "AED", title: t("currency_selector.currencies.AED") },
    { value: "CHF", title: t("currency_selector.currencies.CHF") },
    { value: "DKK", title: t("currency_selector.currencies.DKK") },
    { value: "EGP", title: t("currency_selector.currencies.EGP") },
    { value: "FJD", title: t("currency_selector.currencies.FJD") },
    { value: "GBP", title: t("currency_selector.currencies.GBP") },
    { value: "IDR", title: t("currency_selector.currencies.IDR") },
    { value: "INR", title: t("currency_selector.currencies.INR") },
    { value: "ISK", title: t("currency_selector.currencies.ISK") },
    { value: "JOD", title: t("currency_selector.currencies.JOD") },
    { value: "JPY", title: t("currency_selector.currencies.JPY") },
    { value: "KHR", title: t("currency_selector.currencies.KHR") },
    { value: "LAK", title: t("currency_selector.currencies.LAK") },
    { value: "MAD", title: t("currency_selector.currencies.MAD") },
    { value: "MGA", title: t("currency_selector.currencies.MGA") },
    { value: "MOP", title: t("currency_selector.currencies.MOP") },
    { value: "MUR", title: t("currency_selector.currencies.MUR") },
    { value: "MXN", title: t("currency_selector.currencies.MXN") },
    { value: "NOK", title: t("currency_selector.currencies.NOK") },
    { value: "OMR", title: t("currency_selector.currencies.OMR") },
    { value: "QAR", title: t("currency_selector.currencies.QAR") },
    { value: "SEK", title: t("currency_selector.currencies.SEK") },
    { value: "TRY", title: t("currency_selector.currencies.TRY") },
    { value: "VND", title: t("currency_selector.currencies.VND") },
    { value: "ZAR", title: t("currency_selector.currencies.ZAR") }
  ]
  const pathname = usePathname()
  const store = usePersistStore(useStore, (state) => state)
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleCurrencySelected = (currency: string) => {
    store?.setCurrency(currency);
    window.location.reload();
  }

  return (
    <>
      {
        pathname === "/booking" ? (
          <li className="group/nav relative flex h-full items-center">
            <div
              className="font-degular-display-semibold flex cursor-pointer flex-row items-center space-x-[12px] rounded-full px-[8px] text-base !text-white hover:bg-white/30">
              <p className='font-degular-display-semibold text-nowrap ' onClick={onOpen}>
                {getCurrencySymbol(store?.currency || 'USD')} {store?.currency}
              </p>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='5xl' placement='auto'>
              <ModalContent>
                {() => (
                  <>
                    <ModalBody className='flex-col px-[32px] py-[24px] h-[80vh]'>
                      <p className='text-black text-[20px] px-[1px] font-degular-display-semibold'>{t("currency_selector.select_currency")}</p>
                      <span className='text-[#888] text-[12px] px-[1px]'>{t("currency_selector.currency_info")}</span>
                      <div className='h-[calc(80vh-120px)] px-[1px] overflow-y-auto
                          [&::-webkit-scrollbar]:w-2
                          [&::-webkit-scrollbar-track]:rounded-full
                          [&::-webkit-scrollbar-track]:bg-gray-100
                          [&::-webkit-scrollbar-thumb]:rounded-full
                          [&::-webkit-scrollbar-thumb]:bg-gray-300
                          dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                          dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500'>
                        <p className='text-black text-[20px] my-[16px] font-degular-display-semibold '>{t("currency_selector.suggested_currencies")}</p>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-[30px] lg:gap-x-[60px] gap-y-[12px] lg:gap-y-[20px] px-2'>
                          {
                            suggestedCurrencyList.map((currency, index) => (
                              <button
                                key={index}
                                className={
                                  cn(
                                    'flex flex-col items-start !cursor-pointer hover:bg-[#E7E7E7] p-2 rounded-md',
                                    currency.value == store?.currency && "ring-2 ring-black"
                                  )
                                }
                                onClick={() => handleCurrencySelected(currency.value)}
                              >
                                <p className='text-[14px] text-black text-left '>{currency.title}</p>
                                <p className='text-[12px] text-[#888]'>{currency.value}</p>
                              </button>
                            ))
                          }
                        </div>
                        <p className='text-black text-[20px] my-[16px] font-degular-display-semibold '>{t("currency_selector.more_currencies")}</p>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-[30px] lg:gap-x-[60px] gap-y-[12px] lg:gap-y-[20px] px-2'>
                          {
                            currencyList.map((currency, index) => (
                              <button
                                key={currency.value + index}
                                className={
                                  cn(
                                    'flex flex-col items-start !cursor-pointer hover:bg-[#E7E7E7] p-2 rounded-md',
                                    currency.value == store?.currency && "ring-2 ring-black"
                                  )
                                }
                                onClick={() => handleCurrencySelected(currency.value)}
                              >
                                <p className='text-[14px] text-black text-left '>{currency.title}</p>
                                <p className='text-[12px] text-[#888]'>{currency.value}</p>
                              </button>
                            ))
                          }
                        </div>
                      </div>

                    </ModalBody>
                  </>
                )}
              </ModalContent>
            </Modal >
          </li >
        ) : (
          <li className="group/nav relative h-full items-center hidden 2xl:flex  ">
            <Link
              href="https://supplier.travelthru.com/"
              target="_blank"
              className="!no-underline"
            >
              <div
                className="font-degular-display-semibold flex cursor-pointer flex-row items-center space-x-[12px]
              px-[14px] h-[40px] rounded-[10px] bg-white text-black hover:bg-[#EDEDED] text-[12px] lg:text-[14px]"
              >
                <p className=" text-center leading-none ">{t("become_our_supplier")}</p>
              </div>
            </Link>
          </li>
        )
      }
    </>
  )
}

export default RightNav