export const locales: { [key: string]: string } = {
  en: "English",
  vi: "Tiếng Việt",
  // "fr": "french",
  // "es": "Spanish"
};

export const defaultCountries = {
  en: "US", // English → United States
  vi: "VN", // English → United States
  fr: "FR", // French → France
  de: "DE", // German → Germany
  es: "ES", // Spanish → Spain
  zh: "CN", // Chinese → China
  pt: "PT", // Portuguese → Portugal (or "BR" for Brazil)
};